import { orderPreview } from '../../../api/order.js';
import { Image as VanImage } from 'vant';
import BackOff from '../../common/BackOff.vue';
export default {
  name: 'Preview',
  components: {
    BackOff,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      bgImages: '',
      backUrl: this.$route.query.backUrl,
      userImageX: '',
      userImageY: '',
      userImageWidth: '',
      userImageHeight: '',
      userImageSrc: '',
      coverImg: '',
      coverImgData: {},
      userImgInfo: {},
      orderId: this.$route.query.orderId,
      orderProductId: this.$route.query.orderProductId
    };
  },
  created: async function () {
    this.getOrderProductPreview();
    // this.putUserImage();
  },
  mounted() {
    this.putUserImage();
  },
  methods: {
    getOrderProductPreview() {
      const previewParams = {
        orderId: this.orderId,
        orderProductId: this.orderProductId
      };
      orderPreview(previewParams).then(res => {
        // console.log(res.data)
        this.coverImgData = res.data.data.customData.orderProduct.productDetail.detail.customize[0].coverImgs[0];
        this.userImgInfo = res.data.data.customData.orderProduct.productDetail.detail.customize[0].userImgInfo[0];
        this.bgImages = res.data.data.customData.orderProduct.productDetail.detail.customize[0].bgImages[0].url;
        this.coverImg = this.coverImgData.coverImg;
        // console.log("===========finish=========")
      });
    },
    putUserImage() {
      const previewParams = {
        orderId: this.orderId,
        orderProductId: this.orderProductId
      };
      orderPreview(previewParams).then(res => {
        // console.log(res.data)
        this.coverImgData = res.data.data.customData.orderProduct.productDetail.detail.customize[0].coverImgs[0];
        this.userImgInfo = res.data.data.customData.orderProduct.productDetail.detail.customize[0].userImgInfo[0];
        this.coverImg = this.coverImgData.coverImg;
        // console.log("===========finish=========")
        // 用图片在屏幕里的大小和实际的大小相除,得到缩放比例zoomRate
        const coverData = document.getElementById('custom-canvas-image-box').getBoundingClientRect();
        // console.log("coverData = " + coverData);
        const zoomRate = coverData.width / this.coverImgData.width;
        // console.log("zoomRate = " + zoomRate)
        // 计算用户图片相对于背景图片的位置
        // console.log("this.coverImgData = " + this.coverImgData)
        // console.log(this.coverImgData.width)
        // console.log("this.userImgInfo = " + this.userImgInfo)
        // console.log("this.coverImgData.cusArea.x = " + this.coverImgData.cusArea.x)
        // console.log("this.userImgInfo.x = " + this.userImgInfo.x);
        // eslint-disable-next-line no-eval
        const a = eval(parseInt(this.coverImgData.cusArea.x) + parseInt(this.userImgInfo.x));
        // console.log("a = " + a)
        this.userImageX = a * zoomRate + 'px';
        // this.userImageX = 200 * zoomRate + 'px';

        // console.log('this.userImageX = ' + this.userImageX)
        // this.useImageXPercentage = useImageX / this.coverImgData.width * 100 + '%';
        // eslint-disable-next-line no-eval
        this.userImageY = eval(parseInt(this.coverImgData.cusArea.y) + parseInt(this.userImgInfo.y)) * zoomRate + 'px';
        // this.useImageYPercentage = useImageY / this.coverImgData.height * 100 + '%';
        // 计算用户图片大小
        this.userImageWidth = this.userImgInfo.w * zoomRate + 'px';
        this.userImageHeight = this.userImgInfo.h * zoomRate + 'px';
        this.userImageSrc = this.userImgInfo.photoInfo.url;
      });
    }
  }
};